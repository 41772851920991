const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\testFIS\\testFIS.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Select, Slider } from "antd";
import geoblaze from "geoblaze";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import proj4 from "proj4";

const { Option } = Select;

const epsg32631 = "+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs";
const wgs84 = "+proj=longlat +datum=WGS84 +no_defs";

const availableTimes = [
  "1987-01-01T00:00:00.000Z",
  "1992-01-01T00:00:00.000Z",
  "1997-01-01T00:00:00.000Z",
  "2002-01-01T00:00:00.000Z",
  "2007-01-01T00:00:00.000Z",
  "2012-01-01T00:00:00.000Z",
  "2017-01-01T00:00:00.000Z",
  "2022-01-01T00:00:00.000Z",
];

const propertyOptions = [
  "Aquatic",
  "Forest",
  "HerbaceousCrops",
  "WoodyCrops",
  "Shrublands",
];

const getResolution = () => {
  const width = window.innerWidth;
  return width < 600 ? 32 : width < 1200 ? 48 : 64;
};

const geoToPixel = (georaster, easting, northing) => {
  const { xmin, ymin, xmax, ymax, width, height } = georaster;

  const xResolution = (xmax - xmin) / width;
  const yResolution = (ymax - ymin) / height;

  const col = Math.floor((easting - xmin) / xResolution);
  const row = Math.floor((ymax - northing) / yResolution); // Notice ymax - northing!

  if (col >= 0 && col < width && row >= 0 && row < height) {
    return { col, row };
  } else {
    console.log("Coordinates are out of bounds.");
    return null;
  }
};

const createRaster = async (georaster) => {
  return new GeoRasterLayer({
    georaster: georaster,
    opacity: 0.7,
    resolution: getResolution(),
  });
};

const WMSClient = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [properties, setProperties] = useState("Forest");
  const [time, setTime] = useState(availableTimes[6]);
  const mapRef = useRef(null);
  const leafletMap = useRef(null);

  useEffect(() => {
    if (mapRef.current && !leafletMap.current) {
      console.log("Initializing map...");
      leafletMap.current = L.map(mapRef.current).setView([41.7, 1.7], 7);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(leafletMap.current);
    }
  }, []);

  const updateMap = (rasterLayer, georaster) => {
    if (leafletMap.current && rasterLayer) {
      leafletMap.current.eachLayer((layer) => {
        if (layer instanceof GeoRasterLayer) {
          _optionalChain([leafletMap, 'access', _ => _.current, 'optionalAccess', _2 => _2.removeLayer, 'call', _3 => _3(layer)]);
        }
      });

      rasterLayer.addTo(leafletMap.current);

      const layerBounds = rasterLayer.getBounds();
      leafletMap.current.fitBounds(layerBounds);

      L.rectangle(layerBounds, {
        color: "#ff0000",
        fillOpacity: 0,
        weight: 1,
      }).addTo(leafletMap.current);

      leafletMap.current.on("click", async (e) => {
        const { lat, lng } = e.latlng;

        // Convert WGS84 (Lat, Lng) to EPSG:32631 (Easting, Northing)
        const [easting, northing] = proj4(wgs84, epsg32631, [lng, lat]);

        const pixelCoords = geoToPixel(georaster, easting, northing);

        // Get value using geoblaze.identify with converted coordinates
        const values = await geoblaze.identify(georaster, [easting, northing]);

        console.log(
          `Pixel Value read by clicking at [${easting}, ${northing}]:`,
          values
        );

        if (pixelCoords) {
          const { col, row } = pixelCoords;
          console.log(
            `Clicked at [${easting}, ${northing}] which corresponds to pixel [${row}, ${col}]`
          );

          const rasterValue = georaster.values[0][row][col];
          console.log(
            `Raster Value at georaster.values[0][${row}][${col}]: ${rasterValue}`
          );
        }
      });
    }
  };

  const fetchImage = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `http://localhost:3000/proxy?time=${time}&properties=${properties}`
      );
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();

      if (
        blob.type !== "image/tiff" &&
        blob.type !== "application/octet-stream"
      ) {
        throw new Error("Invalid file format received. Expected a GeoTIFF.");
      }

      const georaster = await geoblaze.parse(arrayBuffer);
      // const georaster1 = await geoblaze.parse(
      //   "https://raw.githubusercontent.com/GeoTIFF/geotiff.io/master/assets/data/PuertoRicoTropicalFruit.tiff"
      // );

      const rasterLayer = await createRaster(georaster);

      console.log(georaster);
      console.log(georaster.values);

      updateMap(rasterLayer, georaster);
    } catch (err) {
      console.error("Error fetching the image:", err);
      setError("Error fetching the image.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImage();
  }, [properties, time]);

  return (
    React.createElement('div', {
      style: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}

      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}, "ODC Test on Leaflet Map"    )

      , React.createElement(Select, {
        defaultValue: properties,
        style: { width: 200 },
        onChange: setProperties, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}

        , propertyOptions.map((property) => (
          React.createElement(Option, { key: property, value: property, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
            , property
          )
        ))
      )

      , React.createElement(Slider, {
        min: 0,
        max: availableTimes.length - 1,
        defaultValue: 6,
        onChange: (value) => setTime(availableTimes[value]),
        marks: {
          0: "1987",
          1: "1992",
          2: "1997",
          3: "2002",
          4: "2007",
          5: "2012",
          6: "2017",
          7: "2022",
        },
        step: 1,
        style: { width: 400, marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}
      )

      , React.createElement('div', {
        id: "map-container",
        style: {
          position: "relative",
          height: "600px",
          width: "60%",
          margin: "30px auto",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}

        , loading && (
          React.createElement('p', {
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 225}}
, "Loading image..."

          )
        )
        , error && (
          React.createElement('p', {
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "red",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}

            , error
          )
        )
        , React.createElement('div', { ref: mapRef, style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 249}})
      )
    )
  );
};

export default WMSClient;
